/**
 * METS API Documentation
 * METS API Documentation
 *
 * The version of the OpenAPI document: uk-pmrv-app-api 0.81.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { AssigneeUserInfoDTO } from '../model/assigneeUserInfoDTO';
import { RequestTaskAssignmentDTO } from '../model/requestTaskAssignmentDTO';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root',
})
export class TasksAssignmentService {
  protected basePath = 'http://localhost:8080/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Assigns a task to a user
   * @param requestTaskAssignmentDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignTask(requestTaskAssignmentDTO: RequestTaskAssignmentDTO): Observable<any>;
  public assignTask(
    requestTaskAssignmentDTO: RequestTaskAssignmentDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public assignTask(
    requestTaskAssignmentDTO: RequestTaskAssignmentDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public assignTask(
    requestTaskAssignmentDTO: RequestTaskAssignmentDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public assignTask(
    requestTaskAssignmentDTO: RequestTaskAssignmentDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (requestTaskAssignmentDTO === null || requestTaskAssignmentDTO === undefined) {
      throw new Error('Required parameter requestTaskAssignmentDTO was null or undefined when calling assignTask.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/v1.0/tasks-assignment/assign`,
      requestTaskAssignmentDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns all users to whom can be assigned the provided task
   * @param taskId The task id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCandidateAssigneesByTaskId(taskId: number): Observable<Array<AssigneeUserInfoDTO>>;
  public getCandidateAssigneesByTaskId(
    taskId: number,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<AssigneeUserInfoDTO>>>;
  public getCandidateAssigneesByTaskId(
    taskId: number,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<AssigneeUserInfoDTO>>>;
  public getCandidateAssigneesByTaskId(
    taskId: number,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<AssigneeUserInfoDTO>>;
  public getCandidateAssigneesByTaskId(
    taskId: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (taskId === null || taskId === undefined) {
      throw new Error('Required parameter taskId was null or undefined when calling getCandidateAssigneesByTaskId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<Array<AssigneeUserInfoDTO>>(
      `${this.configuration.basePath}/v1.0/tasks-assignment/${encodeURIComponent(String(taskId))}/candidate-assignees`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns all users to whom can be assigned the provided task type
   * @param taskId The current task id that user works on. Not related to the task type for which we search candidate assignees
   * @param taskType The task type for which you need to retrieve candidate assignees
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'INSTALLATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'INSTALLATION_ACCOUNT_OPENING_ARCHIVE'
      | 'INSTALLATION_ACCOUNT_TRANSFERRING_ARCHIVE'
      | 'PERMIT_ISSUANCE_APPLICATION_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_REVIEW'
      | 'PERMIT_ISSUANCE_APPLICATION_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_AMENDS'
      | 'PERMIT_ISSUANCE_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_ISSUANCE_APPLICATION_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_ISSUANCE_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_ISSUANCE_MAKE_PAYMENT'
      | 'PERMIT_ISSUANCE_TRACK_PAYMENT'
      | 'PERMIT_ISSUANCE_CONFIRM_PAYMENT'
      | 'PERMIT_SURRENDER_APPLICATION_SUBMIT'
      | 'PERMIT_SURRENDER_APPLICATION_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_REVIEW'
      | 'PERMIT_SURRENDER_APPLICATION_PEER_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_SURRENDER_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_SURRENDER_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_SURRENDER_CESSATION_SUBMIT'
      | 'PERMIT_SURRENDER_MAKE_PAYMENT'
      | 'PERMIT_SURRENDER_TRACK_PAYMENT'
      | 'PERMIT_SURRENDER_CONFIRM_PAYMENT'
      | 'PERMIT_REVOCATION_APPLICATION_SUBMIT'
      | 'PERMIT_REVOCATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_APPEAL'
      | 'PERMIT_REVOCATION_CESSATION_SUBMIT'
      | 'PERMIT_REVOCATION_MAKE_PAYMENT'
      | 'PERMIT_REVOCATION_TRACK_PAYMENT'
      | 'PERMIT_REVOCATION_CONFIRM_PAYMENT'
      | 'PERMIT_NOTIFICATION_APPLICATION_SUBMIT'
      | 'PERMIT_NOTIFICATION_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_AMENDS'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_APPLICATION_REVIEW'
      | 'PERMIT_VARIATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_REVIEW'
      | 'PERMIT_VARIATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_VARIATION_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_VARIATION_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_A_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_A_WAIT_FOR_TRANSFER'
      | 'PERMIT_TRANSFER_A_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_A_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_A_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_B_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_B_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_TRANSFER_B_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_B_APPLICATION_AMENDS_SUBMIT'
      | 'NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'NER_APPLICATION_SUBMIT'
      | 'NER_APPLICATION_REVIEW'
      | 'NER_WAIT_FOR_REVIEW'
      | 'NER_APPLICATION_PEER_REVIEW'
      | 'NER_WAIT_FOR_PEER_REVIEW'
      | 'NER_WAIT_FOR_AMENDS'
      | 'NER_APPLICATION_AMENDS_SUBMIT'
      | 'NER_MAKE_PAYMENT'
      | 'NER_TRACK_PAYMENT'
      | 'NER_CONFIRM_PAYMENT'
      | 'NER_AUTHORITY_RESPONSE'
      | 'DOAL_APPLICATION_SUBMIT'
      | 'DOAL_APPLICATION_PEER_REVIEW'
      | 'DOAL_WAIT_FOR_PEER_REVIEW'
      | 'DOAL_AUTHORITY_RESPONSE'
      | 'AER_APPLICATION_SUBMIT'
      | 'AER_APPLICATION_REVIEW'
      | 'AER_WAIT_FOR_REVIEW'
      | 'AER_APPLICATION_AMENDS_SUBMIT'
      | 'AER_WAIT_FOR_AMENDS'
      | 'AER_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_WAIT_FOR_VERIFICATION'
      | 'AER_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_AMEND_WAIT_FOR_VERIFICATION'
      | 'VIR_APPLICATION_SUBMIT'
      | 'VIR_APPLICATION_REVIEW'
      | 'VIR_WAIT_FOR_REVIEW'
      | 'VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'VIR_RFI_RESPONSE_SUBMIT'
      | 'VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_APPLICATION_SUBMIT'
      | 'AIR_APPLICATION_REVIEW'
      | 'AIR_WAIT_FOR_REVIEW'
      | 'AIR_RFI_RESPONSE_SUBMIT'
      | 'AIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'DRE_APPLICATION_SUBMIT'
      | 'DRE_APPLICATION_PEER_REVIEW'
      | 'DRE_WAIT_FOR_PEER_REVIEW'
      | 'DRE_MAKE_PAYMENT'
      | 'DRE_TRACK_PAYMENT'
      | 'DRE_CONFIRM_PAYMENT'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'WITHHOLDING_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_WITHDRAWAL_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_RETURNED_APPLICATION_SUBMIT'
      | 'INSTALLATION_AUDIT_APPLICATION_SUBMIT'
      | 'INSTALLATION_ONSITE_INSPECTION_APPLICATION_SUBMIT'
      | 'AVIATION_ACCOUNT_CLOSURE_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'AVIATION_VIR_APPLICATION_SUBMIT'
      | 'AVIATION_VIR_APPLICATION_REVIEW'
      | 'AVIATION_VIR_WAIT_FOR_REVIEW'
      | 'AVIATION_VIR_RFI_RESPONSE_SUBMIT'
      | 'AVIATION_VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AVIATION_VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_UKETS_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_REVIEW'
      | 'EMP_VARIATION_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_UKETS_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_REVIEW'
      | 'AVIATION_AER_UKETS_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_UKETS_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_UKETS_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_AMEND_WAIT_FOR_VERIFICATION'
      | 'AVIATION_DRE_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_DRE_UKETS_MAKE_PAYMENT'
      | 'AVIATION_DRE_UKETS_TRACK_PAYMENT'
      | 'AVIATION_DRE_UKETS_CONFIRM_PAYMENT'
      | 'AVIATION_DRE_UKETS_APPLICATION_PEER_REVIEW'
      | 'AVIATION_DRE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_CORSIA_APPLICATION_REVIEW'
      | 'EMP_VARIATION_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'AVIATION_AER_CORSIA_APPLICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_CORSIA_APPLICATION_REVIEW'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_CORSIA_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_AMEND_WAIT_FOR_VERIFICATION'
      | 'ACCOUNT_USERS_SETUP'
      | 'NEW_VERIFICATION_BODY_EMITTER'
      | 'VERIFIER_NO_LONGER_AVAILABLE',
  ): Observable<Array<AssigneeUserInfoDTO>>;
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'INSTALLATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'INSTALLATION_ACCOUNT_OPENING_ARCHIVE'
      | 'INSTALLATION_ACCOUNT_TRANSFERRING_ARCHIVE'
      | 'PERMIT_ISSUANCE_APPLICATION_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_REVIEW'
      | 'PERMIT_ISSUANCE_APPLICATION_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_AMENDS'
      | 'PERMIT_ISSUANCE_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_ISSUANCE_APPLICATION_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_ISSUANCE_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_ISSUANCE_MAKE_PAYMENT'
      | 'PERMIT_ISSUANCE_TRACK_PAYMENT'
      | 'PERMIT_ISSUANCE_CONFIRM_PAYMENT'
      | 'PERMIT_SURRENDER_APPLICATION_SUBMIT'
      | 'PERMIT_SURRENDER_APPLICATION_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_REVIEW'
      | 'PERMIT_SURRENDER_APPLICATION_PEER_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_SURRENDER_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_SURRENDER_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_SURRENDER_CESSATION_SUBMIT'
      | 'PERMIT_SURRENDER_MAKE_PAYMENT'
      | 'PERMIT_SURRENDER_TRACK_PAYMENT'
      | 'PERMIT_SURRENDER_CONFIRM_PAYMENT'
      | 'PERMIT_REVOCATION_APPLICATION_SUBMIT'
      | 'PERMIT_REVOCATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_APPEAL'
      | 'PERMIT_REVOCATION_CESSATION_SUBMIT'
      | 'PERMIT_REVOCATION_MAKE_PAYMENT'
      | 'PERMIT_REVOCATION_TRACK_PAYMENT'
      | 'PERMIT_REVOCATION_CONFIRM_PAYMENT'
      | 'PERMIT_NOTIFICATION_APPLICATION_SUBMIT'
      | 'PERMIT_NOTIFICATION_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_AMENDS'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_APPLICATION_REVIEW'
      | 'PERMIT_VARIATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_REVIEW'
      | 'PERMIT_VARIATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_VARIATION_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_VARIATION_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_A_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_A_WAIT_FOR_TRANSFER'
      | 'PERMIT_TRANSFER_A_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_A_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_A_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_B_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_B_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_TRANSFER_B_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_B_APPLICATION_AMENDS_SUBMIT'
      | 'NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'NER_APPLICATION_SUBMIT'
      | 'NER_APPLICATION_REVIEW'
      | 'NER_WAIT_FOR_REVIEW'
      | 'NER_APPLICATION_PEER_REVIEW'
      | 'NER_WAIT_FOR_PEER_REVIEW'
      | 'NER_WAIT_FOR_AMENDS'
      | 'NER_APPLICATION_AMENDS_SUBMIT'
      | 'NER_MAKE_PAYMENT'
      | 'NER_TRACK_PAYMENT'
      | 'NER_CONFIRM_PAYMENT'
      | 'NER_AUTHORITY_RESPONSE'
      | 'DOAL_APPLICATION_SUBMIT'
      | 'DOAL_APPLICATION_PEER_REVIEW'
      | 'DOAL_WAIT_FOR_PEER_REVIEW'
      | 'DOAL_AUTHORITY_RESPONSE'
      | 'AER_APPLICATION_SUBMIT'
      | 'AER_APPLICATION_REVIEW'
      | 'AER_WAIT_FOR_REVIEW'
      | 'AER_APPLICATION_AMENDS_SUBMIT'
      | 'AER_WAIT_FOR_AMENDS'
      | 'AER_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_WAIT_FOR_VERIFICATION'
      | 'AER_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_AMEND_WAIT_FOR_VERIFICATION'
      | 'VIR_APPLICATION_SUBMIT'
      | 'VIR_APPLICATION_REVIEW'
      | 'VIR_WAIT_FOR_REVIEW'
      | 'VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'VIR_RFI_RESPONSE_SUBMIT'
      | 'VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_APPLICATION_SUBMIT'
      | 'AIR_APPLICATION_REVIEW'
      | 'AIR_WAIT_FOR_REVIEW'
      | 'AIR_RFI_RESPONSE_SUBMIT'
      | 'AIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'DRE_APPLICATION_SUBMIT'
      | 'DRE_APPLICATION_PEER_REVIEW'
      | 'DRE_WAIT_FOR_PEER_REVIEW'
      | 'DRE_MAKE_PAYMENT'
      | 'DRE_TRACK_PAYMENT'
      | 'DRE_CONFIRM_PAYMENT'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'WITHHOLDING_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_WITHDRAWAL_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_RETURNED_APPLICATION_SUBMIT'
      | 'INSTALLATION_AUDIT_APPLICATION_SUBMIT'
      | 'INSTALLATION_ONSITE_INSPECTION_APPLICATION_SUBMIT'
      | 'AVIATION_ACCOUNT_CLOSURE_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'AVIATION_VIR_APPLICATION_SUBMIT'
      | 'AVIATION_VIR_APPLICATION_REVIEW'
      | 'AVIATION_VIR_WAIT_FOR_REVIEW'
      | 'AVIATION_VIR_RFI_RESPONSE_SUBMIT'
      | 'AVIATION_VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AVIATION_VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_UKETS_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_REVIEW'
      | 'EMP_VARIATION_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_UKETS_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_REVIEW'
      | 'AVIATION_AER_UKETS_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_UKETS_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_UKETS_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_AMEND_WAIT_FOR_VERIFICATION'
      | 'AVIATION_DRE_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_DRE_UKETS_MAKE_PAYMENT'
      | 'AVIATION_DRE_UKETS_TRACK_PAYMENT'
      | 'AVIATION_DRE_UKETS_CONFIRM_PAYMENT'
      | 'AVIATION_DRE_UKETS_APPLICATION_PEER_REVIEW'
      | 'AVIATION_DRE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_CORSIA_APPLICATION_REVIEW'
      | 'EMP_VARIATION_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'AVIATION_AER_CORSIA_APPLICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_CORSIA_APPLICATION_REVIEW'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_CORSIA_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_AMEND_WAIT_FOR_VERIFICATION'
      | 'ACCOUNT_USERS_SETUP'
      | 'NEW_VERIFICATION_BODY_EMITTER'
      | 'VERIFIER_NO_LONGER_AVAILABLE',
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<AssigneeUserInfoDTO>>>;
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'INSTALLATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'INSTALLATION_ACCOUNT_OPENING_ARCHIVE'
      | 'INSTALLATION_ACCOUNT_TRANSFERRING_ARCHIVE'
      | 'PERMIT_ISSUANCE_APPLICATION_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_REVIEW'
      | 'PERMIT_ISSUANCE_APPLICATION_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_AMENDS'
      | 'PERMIT_ISSUANCE_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_ISSUANCE_APPLICATION_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_ISSUANCE_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_ISSUANCE_MAKE_PAYMENT'
      | 'PERMIT_ISSUANCE_TRACK_PAYMENT'
      | 'PERMIT_ISSUANCE_CONFIRM_PAYMENT'
      | 'PERMIT_SURRENDER_APPLICATION_SUBMIT'
      | 'PERMIT_SURRENDER_APPLICATION_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_REVIEW'
      | 'PERMIT_SURRENDER_APPLICATION_PEER_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_SURRENDER_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_SURRENDER_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_SURRENDER_CESSATION_SUBMIT'
      | 'PERMIT_SURRENDER_MAKE_PAYMENT'
      | 'PERMIT_SURRENDER_TRACK_PAYMENT'
      | 'PERMIT_SURRENDER_CONFIRM_PAYMENT'
      | 'PERMIT_REVOCATION_APPLICATION_SUBMIT'
      | 'PERMIT_REVOCATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_APPEAL'
      | 'PERMIT_REVOCATION_CESSATION_SUBMIT'
      | 'PERMIT_REVOCATION_MAKE_PAYMENT'
      | 'PERMIT_REVOCATION_TRACK_PAYMENT'
      | 'PERMIT_REVOCATION_CONFIRM_PAYMENT'
      | 'PERMIT_NOTIFICATION_APPLICATION_SUBMIT'
      | 'PERMIT_NOTIFICATION_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_AMENDS'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_APPLICATION_REVIEW'
      | 'PERMIT_VARIATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_REVIEW'
      | 'PERMIT_VARIATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_VARIATION_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_VARIATION_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_A_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_A_WAIT_FOR_TRANSFER'
      | 'PERMIT_TRANSFER_A_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_A_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_A_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_B_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_B_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_TRANSFER_B_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_B_APPLICATION_AMENDS_SUBMIT'
      | 'NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'NER_APPLICATION_SUBMIT'
      | 'NER_APPLICATION_REVIEW'
      | 'NER_WAIT_FOR_REVIEW'
      | 'NER_APPLICATION_PEER_REVIEW'
      | 'NER_WAIT_FOR_PEER_REVIEW'
      | 'NER_WAIT_FOR_AMENDS'
      | 'NER_APPLICATION_AMENDS_SUBMIT'
      | 'NER_MAKE_PAYMENT'
      | 'NER_TRACK_PAYMENT'
      | 'NER_CONFIRM_PAYMENT'
      | 'NER_AUTHORITY_RESPONSE'
      | 'DOAL_APPLICATION_SUBMIT'
      | 'DOAL_APPLICATION_PEER_REVIEW'
      | 'DOAL_WAIT_FOR_PEER_REVIEW'
      | 'DOAL_AUTHORITY_RESPONSE'
      | 'AER_APPLICATION_SUBMIT'
      | 'AER_APPLICATION_REVIEW'
      | 'AER_WAIT_FOR_REVIEW'
      | 'AER_APPLICATION_AMENDS_SUBMIT'
      | 'AER_WAIT_FOR_AMENDS'
      | 'AER_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_WAIT_FOR_VERIFICATION'
      | 'AER_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_AMEND_WAIT_FOR_VERIFICATION'
      | 'VIR_APPLICATION_SUBMIT'
      | 'VIR_APPLICATION_REVIEW'
      | 'VIR_WAIT_FOR_REVIEW'
      | 'VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'VIR_RFI_RESPONSE_SUBMIT'
      | 'VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_APPLICATION_SUBMIT'
      | 'AIR_APPLICATION_REVIEW'
      | 'AIR_WAIT_FOR_REVIEW'
      | 'AIR_RFI_RESPONSE_SUBMIT'
      | 'AIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'DRE_APPLICATION_SUBMIT'
      | 'DRE_APPLICATION_PEER_REVIEW'
      | 'DRE_WAIT_FOR_PEER_REVIEW'
      | 'DRE_MAKE_PAYMENT'
      | 'DRE_TRACK_PAYMENT'
      | 'DRE_CONFIRM_PAYMENT'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'WITHHOLDING_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_WITHDRAWAL_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_RETURNED_APPLICATION_SUBMIT'
      | 'INSTALLATION_AUDIT_APPLICATION_SUBMIT'
      | 'INSTALLATION_ONSITE_INSPECTION_APPLICATION_SUBMIT'
      | 'AVIATION_ACCOUNT_CLOSURE_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'AVIATION_VIR_APPLICATION_SUBMIT'
      | 'AVIATION_VIR_APPLICATION_REVIEW'
      | 'AVIATION_VIR_WAIT_FOR_REVIEW'
      | 'AVIATION_VIR_RFI_RESPONSE_SUBMIT'
      | 'AVIATION_VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AVIATION_VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_UKETS_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_REVIEW'
      | 'EMP_VARIATION_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_UKETS_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_REVIEW'
      | 'AVIATION_AER_UKETS_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_UKETS_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_UKETS_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_AMEND_WAIT_FOR_VERIFICATION'
      | 'AVIATION_DRE_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_DRE_UKETS_MAKE_PAYMENT'
      | 'AVIATION_DRE_UKETS_TRACK_PAYMENT'
      | 'AVIATION_DRE_UKETS_CONFIRM_PAYMENT'
      | 'AVIATION_DRE_UKETS_APPLICATION_PEER_REVIEW'
      | 'AVIATION_DRE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_CORSIA_APPLICATION_REVIEW'
      | 'EMP_VARIATION_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'AVIATION_AER_CORSIA_APPLICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_CORSIA_APPLICATION_REVIEW'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_CORSIA_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_AMEND_WAIT_FOR_VERIFICATION'
      | 'ACCOUNT_USERS_SETUP'
      | 'NEW_VERIFICATION_BODY_EMITTER'
      | 'VERIFIER_NO_LONGER_AVAILABLE',
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<AssigneeUserInfoDTO>>>;
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'INSTALLATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'INSTALLATION_ACCOUNT_OPENING_ARCHIVE'
      | 'INSTALLATION_ACCOUNT_TRANSFERRING_ARCHIVE'
      | 'PERMIT_ISSUANCE_APPLICATION_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_REVIEW'
      | 'PERMIT_ISSUANCE_APPLICATION_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_AMENDS'
      | 'PERMIT_ISSUANCE_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_ISSUANCE_APPLICATION_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_ISSUANCE_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_ISSUANCE_MAKE_PAYMENT'
      | 'PERMIT_ISSUANCE_TRACK_PAYMENT'
      | 'PERMIT_ISSUANCE_CONFIRM_PAYMENT'
      | 'PERMIT_SURRENDER_APPLICATION_SUBMIT'
      | 'PERMIT_SURRENDER_APPLICATION_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_REVIEW'
      | 'PERMIT_SURRENDER_APPLICATION_PEER_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_SURRENDER_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_SURRENDER_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_SURRENDER_CESSATION_SUBMIT'
      | 'PERMIT_SURRENDER_MAKE_PAYMENT'
      | 'PERMIT_SURRENDER_TRACK_PAYMENT'
      | 'PERMIT_SURRENDER_CONFIRM_PAYMENT'
      | 'PERMIT_REVOCATION_APPLICATION_SUBMIT'
      | 'PERMIT_REVOCATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_APPEAL'
      | 'PERMIT_REVOCATION_CESSATION_SUBMIT'
      | 'PERMIT_REVOCATION_MAKE_PAYMENT'
      | 'PERMIT_REVOCATION_TRACK_PAYMENT'
      | 'PERMIT_REVOCATION_CONFIRM_PAYMENT'
      | 'PERMIT_NOTIFICATION_APPLICATION_SUBMIT'
      | 'PERMIT_NOTIFICATION_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_AMENDS'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_APPLICATION_REVIEW'
      | 'PERMIT_VARIATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_REVIEW'
      | 'PERMIT_VARIATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_VARIATION_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_VARIATION_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_A_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_A_WAIT_FOR_TRANSFER'
      | 'PERMIT_TRANSFER_A_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_A_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_A_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_B_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_B_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_TRANSFER_B_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_B_APPLICATION_AMENDS_SUBMIT'
      | 'NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'NER_APPLICATION_SUBMIT'
      | 'NER_APPLICATION_REVIEW'
      | 'NER_WAIT_FOR_REVIEW'
      | 'NER_APPLICATION_PEER_REVIEW'
      | 'NER_WAIT_FOR_PEER_REVIEW'
      | 'NER_WAIT_FOR_AMENDS'
      | 'NER_APPLICATION_AMENDS_SUBMIT'
      | 'NER_MAKE_PAYMENT'
      | 'NER_TRACK_PAYMENT'
      | 'NER_CONFIRM_PAYMENT'
      | 'NER_AUTHORITY_RESPONSE'
      | 'DOAL_APPLICATION_SUBMIT'
      | 'DOAL_APPLICATION_PEER_REVIEW'
      | 'DOAL_WAIT_FOR_PEER_REVIEW'
      | 'DOAL_AUTHORITY_RESPONSE'
      | 'AER_APPLICATION_SUBMIT'
      | 'AER_APPLICATION_REVIEW'
      | 'AER_WAIT_FOR_REVIEW'
      | 'AER_APPLICATION_AMENDS_SUBMIT'
      | 'AER_WAIT_FOR_AMENDS'
      | 'AER_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_WAIT_FOR_VERIFICATION'
      | 'AER_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_AMEND_WAIT_FOR_VERIFICATION'
      | 'VIR_APPLICATION_SUBMIT'
      | 'VIR_APPLICATION_REVIEW'
      | 'VIR_WAIT_FOR_REVIEW'
      | 'VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'VIR_RFI_RESPONSE_SUBMIT'
      | 'VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_APPLICATION_SUBMIT'
      | 'AIR_APPLICATION_REVIEW'
      | 'AIR_WAIT_FOR_REVIEW'
      | 'AIR_RFI_RESPONSE_SUBMIT'
      | 'AIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'DRE_APPLICATION_SUBMIT'
      | 'DRE_APPLICATION_PEER_REVIEW'
      | 'DRE_WAIT_FOR_PEER_REVIEW'
      | 'DRE_MAKE_PAYMENT'
      | 'DRE_TRACK_PAYMENT'
      | 'DRE_CONFIRM_PAYMENT'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'WITHHOLDING_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_WITHDRAWAL_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_RETURNED_APPLICATION_SUBMIT'
      | 'INSTALLATION_AUDIT_APPLICATION_SUBMIT'
      | 'INSTALLATION_ONSITE_INSPECTION_APPLICATION_SUBMIT'
      | 'AVIATION_ACCOUNT_CLOSURE_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'AVIATION_VIR_APPLICATION_SUBMIT'
      | 'AVIATION_VIR_APPLICATION_REVIEW'
      | 'AVIATION_VIR_WAIT_FOR_REVIEW'
      | 'AVIATION_VIR_RFI_RESPONSE_SUBMIT'
      | 'AVIATION_VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AVIATION_VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_UKETS_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_REVIEW'
      | 'EMP_VARIATION_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_UKETS_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_REVIEW'
      | 'AVIATION_AER_UKETS_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_UKETS_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_UKETS_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_AMEND_WAIT_FOR_VERIFICATION'
      | 'AVIATION_DRE_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_DRE_UKETS_MAKE_PAYMENT'
      | 'AVIATION_DRE_UKETS_TRACK_PAYMENT'
      | 'AVIATION_DRE_UKETS_CONFIRM_PAYMENT'
      | 'AVIATION_DRE_UKETS_APPLICATION_PEER_REVIEW'
      | 'AVIATION_DRE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_CORSIA_APPLICATION_REVIEW'
      | 'EMP_VARIATION_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'AVIATION_AER_CORSIA_APPLICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_CORSIA_APPLICATION_REVIEW'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_CORSIA_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_AMEND_WAIT_FOR_VERIFICATION'
      | 'ACCOUNT_USERS_SETUP'
      | 'NEW_VERIFICATION_BODY_EMITTER'
      | 'VERIFIER_NO_LONGER_AVAILABLE',
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<AssigneeUserInfoDTO>>;
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'INSTALLATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'INSTALLATION_ACCOUNT_OPENING_ARCHIVE'
      | 'INSTALLATION_ACCOUNT_TRANSFERRING_ARCHIVE'
      | 'PERMIT_ISSUANCE_APPLICATION_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_REVIEW'
      | 'PERMIT_ISSUANCE_APPLICATION_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_AMENDS'
      | 'PERMIT_ISSUANCE_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_ISSUANCE_APPLICATION_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_ISSUANCE_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_ISSUANCE_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_ISSUANCE_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_ISSUANCE_MAKE_PAYMENT'
      | 'PERMIT_ISSUANCE_TRACK_PAYMENT'
      | 'PERMIT_ISSUANCE_CONFIRM_PAYMENT'
      | 'PERMIT_SURRENDER_APPLICATION_SUBMIT'
      | 'PERMIT_SURRENDER_APPLICATION_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_REVIEW'
      | 'PERMIT_SURRENDER_APPLICATION_PEER_REVIEW'
      | 'PERMIT_SURRENDER_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_SURRENDER_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_SURRENDER_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_SURRENDER_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_SURRENDER_CESSATION_SUBMIT'
      | 'PERMIT_SURRENDER_MAKE_PAYMENT'
      | 'PERMIT_SURRENDER_TRACK_PAYMENT'
      | 'PERMIT_SURRENDER_CONFIRM_PAYMENT'
      | 'PERMIT_REVOCATION_APPLICATION_SUBMIT'
      | 'PERMIT_REVOCATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_REVOCATION_WAIT_FOR_APPEAL'
      | 'PERMIT_REVOCATION_CESSATION_SUBMIT'
      | 'PERMIT_REVOCATION_MAKE_PAYMENT'
      | 'PERMIT_REVOCATION_TRACK_PAYMENT'
      | 'PERMIT_REVOCATION_CONFIRM_PAYMENT'
      | 'PERMIT_NOTIFICATION_APPLICATION_SUBMIT'
      | 'PERMIT_NOTIFICATION_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_NOTIFICATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_WAIT_FOR_FOLLOW_UP'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_REVIEW'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_WAIT_FOR_AMENDS'
      | 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_REGULATOR_LED_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_SUBMIT'
      | 'PERMIT_VARIATION_APPLICATION_REVIEW'
      | 'PERMIT_VARIATION_APPLICATION_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_VARIATION_WAIT_FOR_REVIEW'
      | 'PERMIT_VARIATION_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_VARIATION_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_VARIATION_MAKE_PAYMENT'
      | 'PERMIT_VARIATION_TRACK_PAYMENT'
      | 'PERMIT_VARIATION_CONFIRM_PAYMENT'
      | 'PERMIT_VARIATION_APPLICATION_AMENDS_SUBMIT'
      | 'PERMIT_VARIATION_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_A_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_A_WAIT_FOR_TRANSFER'
      | 'PERMIT_TRANSFER_A_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_A_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_A_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_APPLICATION_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_REVIEW'
      | 'PERMIT_TRANSFER_B_APPLICATION_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_PEER_REVIEW'
      | 'PERMIT_TRANSFER_B_MAKE_PAYMENT'
      | 'PERMIT_TRANSFER_B_TRACK_PAYMENT'
      | 'PERMIT_TRANSFER_B_CONFIRM_PAYMENT'
      | 'PERMIT_TRANSFER_B_RFI_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RFI_RESPONSE'
      | 'PERMIT_TRANSFER_B_RDE_RESPONSE_SUBMIT'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_RDE_RESPONSE'
      | 'PERMIT_TRANSFER_B_WAIT_FOR_AMENDS'
      | 'PERMIT_TRANSFER_B_APPLICATION_AMENDS_SUBMIT'
      | 'NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'NER_APPLICATION_SUBMIT'
      | 'NER_APPLICATION_REVIEW'
      | 'NER_WAIT_FOR_REVIEW'
      | 'NER_APPLICATION_PEER_REVIEW'
      | 'NER_WAIT_FOR_PEER_REVIEW'
      | 'NER_WAIT_FOR_AMENDS'
      | 'NER_APPLICATION_AMENDS_SUBMIT'
      | 'NER_MAKE_PAYMENT'
      | 'NER_TRACK_PAYMENT'
      | 'NER_CONFIRM_PAYMENT'
      | 'NER_AUTHORITY_RESPONSE'
      | 'DOAL_APPLICATION_SUBMIT'
      | 'DOAL_APPLICATION_PEER_REVIEW'
      | 'DOAL_WAIT_FOR_PEER_REVIEW'
      | 'DOAL_AUTHORITY_RESPONSE'
      | 'AER_APPLICATION_SUBMIT'
      | 'AER_APPLICATION_REVIEW'
      | 'AER_WAIT_FOR_REVIEW'
      | 'AER_APPLICATION_AMENDS_SUBMIT'
      | 'AER_WAIT_FOR_AMENDS'
      | 'AER_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_WAIT_FOR_VERIFICATION'
      | 'AER_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AER_AMEND_WAIT_FOR_VERIFICATION'
      | 'VIR_APPLICATION_SUBMIT'
      | 'VIR_APPLICATION_REVIEW'
      | 'VIR_WAIT_FOR_REVIEW'
      | 'VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'VIR_RFI_RESPONSE_SUBMIT'
      | 'VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_APPLICATION_SUBMIT'
      | 'AIR_APPLICATION_REVIEW'
      | 'AIR_WAIT_FOR_REVIEW'
      | 'AIR_RFI_RESPONSE_SUBMIT'
      | 'AIR_WAIT_FOR_RFI_RESPONSE'
      | 'AIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'DRE_APPLICATION_SUBMIT'
      | 'DRE_APPLICATION_PEER_REVIEW'
      | 'DRE_WAIT_FOR_PEER_REVIEW'
      | 'DRE_MAKE_PAYMENT'
      | 'DRE_TRACK_PAYMENT'
      | 'DRE_CONFIRM_PAYMENT'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'WITHHOLDING_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'WITHHOLDING_OF_ALLOWANCES_WITHDRAWAL_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_SUBMIT'
      | 'RETURN_OF_ALLOWANCES_APPLICATION_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW'
      | 'RETURN_OF_ALLOWANCES_RETURNED_APPLICATION_SUBMIT'
      | 'INSTALLATION_AUDIT_APPLICATION_SUBMIT'
      | 'INSTALLATION_ONSITE_INSPECTION_APPLICATION_SUBMIT'
      | 'AVIATION_ACCOUNT_CLOSURE_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_APPLICATION_SUBMIT'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_WAIT_FOR_PEER_REVIEW'
      | 'AVIATION_NON_COMPLIANCE_FINAL_DETERMINATION'
      | 'AVIATION_VIR_APPLICATION_SUBMIT'
      | 'AVIATION_VIR_APPLICATION_REVIEW'
      | 'AVIATION_VIR_WAIT_FOR_REVIEW'
      | 'AVIATION_VIR_RFI_RESPONSE_SUBMIT'
      | 'AVIATION_VIR_WAIT_FOR_RFI_RESPONSE'
      | 'AVIATION_VIR_RESPOND_TO_REGULATOR_COMMENTS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_UKETS_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_ISSUANCE_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_UKETS_APPLICATION_REVIEW'
      | 'EMP_VARIATION_UKETS_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_UKETS_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_UKETS_MAKE_PAYMENT'
      | 'EMP_VARIATION_UKETS_TRACK_PAYMENT'
      | 'EMP_VARIATION_UKETS_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_UKETS_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_UKETS_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_APPLICATION_REVIEW'
      | 'AVIATION_AER_UKETS_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_UKETS_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_UKETS_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_UKETS_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_UKETS_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_UKETS_AMEND_WAIT_FOR_VERIFICATION'
      | 'AVIATION_DRE_UKETS_APPLICATION_SUBMIT'
      | 'AVIATION_DRE_UKETS_MAKE_PAYMENT'
      | 'AVIATION_DRE_UKETS_TRACK_PAYMENT'
      | 'AVIATION_DRE_UKETS_CONFIRM_PAYMENT'
      | 'AVIATION_DRE_UKETS_APPLICATION_PEER_REVIEW'
      | 'AVIATION_DRE_UKETS_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_ISSUANCE_CORSIA_MAKE_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_TRACK_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_ISSUANCE_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_ISSUANCE_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_REVIEW'
      | 'EMP_VARIATION_CORSIA_APPLICATION_REVIEW'
      | 'EMP_VARIATION_CORSIA_RFI_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RFI_RESPONSE'
      | 'EMP_VARIATION_CORSIA_RDE_RESPONSE_SUBMIT'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_RDE_RESPONSE'
      | 'EMP_VARIATION_CORSIA_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_WAIT_FOR_AMENDS'
      | 'EMP_VARIATION_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'EMP_VARIATION_CORSIA_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_CONFIRM_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_SUBMIT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_WAIT_FOR_PEER_REVIEW'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_MAKE_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_TRACK_PAYMENT'
      | 'EMP_VARIATION_CORSIA_REGULATOR_LED_CONFIRM_PAYMENT'
      | 'AVIATION_AER_CORSIA_APPLICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_VERIFICATION'
      | 'AVIATION_AER_CORSIA_APPLICATION_REVIEW'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_REVIEW'
      | 'AVIATION_AER_CORSIA_APPLICATION_AMENDS_SUBMIT'
      | 'AVIATION_AER_CORSIA_WAIT_FOR_AMENDS'
      | 'AVIATION_AER_CORSIA_AMEND_APPLICATION_VERIFICATION_SUBMIT'
      | 'AVIATION_AER_CORSIA_AMEND_WAIT_FOR_VERIFICATION'
      | 'ACCOUNT_USERS_SETUP'
      | 'NEW_VERIFICATION_BODY_EMITTER'
      | 'VERIFIER_NO_LONGER_AVAILABLE',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (taskId === null || taskId === undefined) {
      throw new Error('Required parameter taskId was null or undefined when calling getCandidateAssigneesByTaskType.');
    }
    if (taskType === null || taskType === undefined) {
      throw new Error(
        'Required parameter taskType was null or undefined when calling getCandidateAssigneesByTaskType.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<Array<AssigneeUserInfoDTO>>(
      `${this.configuration.basePath}/v1.0/tasks-assignment/${encodeURIComponent(String(taskId))}/candidate-assignees/${encodeURIComponent(String(taskType))}`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
