/**
 * METS API Documentation
 * METS API Documentation
 *
 * The version of the OpenAPI document: uk-pmrv-app-api 0.81.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { AccountUpdateFaStatusDTO } from '../model/accountUpdateFaStatusDTO';
import { AccountUpdateInstallationNameDTO } from '../model/accountUpdateInstallationNameDTO';
import { AccountUpdateRegistryIdDTO } from '../model/accountUpdateRegistryIdDTO';
import { AccountUpdateSiteNameDTO } from '../model/accountUpdateSiteNameDTO';
import { AccountUpdateSopIdDTO } from '../model/accountUpdateSopIdDTO';
import { LegalEntityDTO } from '../model/legalEntityDTO';
import { LocationDTO } from '../model/locationDTO';
import { LocationOffShoreDTO } from '../model/locationOffShoreDTO';
import { LocationOnShoreDTO } from '../model/locationOnShoreDTO';
import { LocationOnShoreStateDTO } from '../model/locationOnShoreStateDTO';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root',
})
export class InstallationAccountUpdateService {
  protected basePath = 'http://localhost:8080/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Update the installation free allocation status of the account
   * @param id The account id
   * @param accountUpdateFaStatusDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateFaStatus(id: number, accountUpdateFaStatusDTO: AccountUpdateFaStatusDTO): Observable<any>;
  public updateFaStatus(
    id: number,
    accountUpdateFaStatusDTO: AccountUpdateFaStatusDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public updateFaStatus(
    id: number,
    accountUpdateFaStatusDTO: AccountUpdateFaStatusDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public updateFaStatus(
    id: number,
    accountUpdateFaStatusDTO: AccountUpdateFaStatusDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public updateFaStatus(
    id: number,
    accountUpdateFaStatusDTO: AccountUpdateFaStatusDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateFaStatus.');
    }
    if (accountUpdateFaStatusDTO === null || accountUpdateFaStatusDTO === undefined) {
      throw new Error('Required parameter accountUpdateFaStatusDTO was null or undefined when calling updateFaStatus.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/v1.0/installation/accounts/${encodeURIComponent(String(id))}/free-allocation-status`,
      accountUpdateFaStatusDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Updates the address of the account
   * @param id The account id
   * @param locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInstallationAccountAddress(
    id: number,
    locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO:
      | LocationDTO
      | LocationOffShoreDTO
      | LocationOnShoreDTO
      | LocationOnShoreStateDTO,
  ): Observable<any>;
  public updateInstallationAccountAddress(
    id: number,
    locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO:
      | LocationDTO
      | LocationOffShoreDTO
      | LocationOnShoreDTO
      | LocationOnShoreStateDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public updateInstallationAccountAddress(
    id: number,
    locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO:
      | LocationDTO
      | LocationOffShoreDTO
      | LocationOnShoreDTO
      | LocationOnShoreStateDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public updateInstallationAccountAddress(
    id: number,
    locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO:
      | LocationDTO
      | LocationOffShoreDTO
      | LocationOnShoreDTO
      | LocationOnShoreStateDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public updateInstallationAccountAddress(
    id: number,
    locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO:
      | LocationDTO
      | LocationOffShoreDTO
      | LocationOnShoreDTO
      | LocationOnShoreStateDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateInstallationAccountAddress.');
    }
    if (
      locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO === null ||
      locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO === undefined
    ) {
      throw new Error(
        'Required parameter locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO was null or undefined when calling updateInstallationAccountAddress.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/v1.0/installation/accounts/${encodeURIComponent(String(id))}/address`,
      locationDTOLocationOffShoreDTOLocationOnShoreDTOLocationOnShoreStateDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Updates the legal entity of the account
   * @param id The account id
   * @param legalEntityDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInstallationAccountLegalEntity(id: number, legalEntityDTO: LegalEntityDTO): Observable<any>;
  public updateInstallationAccountLegalEntity(
    id: number,
    legalEntityDTO: LegalEntityDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public updateInstallationAccountLegalEntity(
    id: number,
    legalEntityDTO: LegalEntityDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public updateInstallationAccountLegalEntity(
    id: number,
    legalEntityDTO: LegalEntityDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public updateInstallationAccountLegalEntity(
    id: number,
    legalEntityDTO: LegalEntityDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateInstallationAccountLegalEntity.');
    }
    if (legalEntityDTO === null || legalEntityDTO === undefined) {
      throw new Error(
        'Required parameter legalEntityDTO was null or undefined when calling updateInstallationAccountLegalEntity.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/v1.0/installation/accounts/${encodeURIComponent(String(id))}/legal-entity`,
      legalEntityDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update the uk ets registry id of the account
   * @param id The account id
   * @param accountUpdateRegistryIdDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInstallationAccountRegistryId(
    id: number,
    accountUpdateRegistryIdDTO: AccountUpdateRegistryIdDTO,
  ): Observable<any>;
  public updateInstallationAccountRegistryId(
    id: number,
    accountUpdateRegistryIdDTO: AccountUpdateRegistryIdDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public updateInstallationAccountRegistryId(
    id: number,
    accountUpdateRegistryIdDTO: AccountUpdateRegistryIdDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public updateInstallationAccountRegistryId(
    id: number,
    accountUpdateRegistryIdDTO: AccountUpdateRegistryIdDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public updateInstallationAccountRegistryId(
    id: number,
    accountUpdateRegistryIdDTO: AccountUpdateRegistryIdDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateInstallationAccountRegistryId.');
    }
    if (accountUpdateRegistryIdDTO === null || accountUpdateRegistryIdDTO === undefined) {
      throw new Error(
        'Required parameter accountUpdateRegistryIdDTO was null or undefined when calling updateInstallationAccountRegistryId.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/v1.0/installation/accounts/${encodeURIComponent(String(id))}/registry-id`,
      accountUpdateRegistryIdDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update the site name of the account
   * @param id The account id
   * @param accountUpdateSiteNameDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInstallationAccountSiteName(
    id: number,
    accountUpdateSiteNameDTO: AccountUpdateSiteNameDTO,
  ): Observable<any>;
  public updateInstallationAccountSiteName(
    id: number,
    accountUpdateSiteNameDTO: AccountUpdateSiteNameDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public updateInstallationAccountSiteName(
    id: number,
    accountUpdateSiteNameDTO: AccountUpdateSiteNameDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public updateInstallationAccountSiteName(
    id: number,
    accountUpdateSiteNameDTO: AccountUpdateSiteNameDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public updateInstallationAccountSiteName(
    id: number,
    accountUpdateSiteNameDTO: AccountUpdateSiteNameDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateInstallationAccountSiteName.');
    }
    if (accountUpdateSiteNameDTO === null || accountUpdateSiteNameDTO === undefined) {
      throw new Error(
        'Required parameter accountUpdateSiteNameDTO was null or undefined when calling updateInstallationAccountSiteName.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/v1.0/installation/accounts/${encodeURIComponent(String(id))}/site-name`,
      accountUpdateSiteNameDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update the sop id of the account
   * @param id The account id
   * @param accountUpdateSopIdDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInstallationAccountSopId(id: number, accountUpdateSopIdDTO: AccountUpdateSopIdDTO): Observable<any>;
  public updateInstallationAccountSopId(
    id: number,
    accountUpdateSopIdDTO: AccountUpdateSopIdDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public updateInstallationAccountSopId(
    id: number,
    accountUpdateSopIdDTO: AccountUpdateSopIdDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public updateInstallationAccountSopId(
    id: number,
    accountUpdateSopIdDTO: AccountUpdateSopIdDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public updateInstallationAccountSopId(
    id: number,
    accountUpdateSopIdDTO: AccountUpdateSopIdDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateInstallationAccountSopId.');
    }
    if (accountUpdateSopIdDTO === null || accountUpdateSopIdDTO === undefined) {
      throw new Error(
        'Required parameter accountUpdateSopIdDTO was null or undefined when calling updateInstallationAccountSopId.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/v1.0/installation/accounts/${encodeURIComponent(String(id))}/sop-id`,
      accountUpdateSopIdDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update the installation name of the account
   * @param id The account id
   * @param accountUpdateInstallationNameDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInstallationName(
    id: number,
    accountUpdateInstallationNameDTO: AccountUpdateInstallationNameDTO,
  ): Observable<any>;
  public updateInstallationName(
    id: number,
    accountUpdateInstallationNameDTO: AccountUpdateInstallationNameDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public updateInstallationName(
    id: number,
    accountUpdateInstallationNameDTO: AccountUpdateInstallationNameDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public updateInstallationName(
    id: number,
    accountUpdateInstallationNameDTO: AccountUpdateInstallationNameDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public updateInstallationName(
    id: number,
    accountUpdateInstallationNameDTO: AccountUpdateInstallationNameDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateInstallationName.');
    }
    if (accountUpdateInstallationNameDTO === null || accountUpdateInstallationNameDTO === undefined) {
      throw new Error(
        'Required parameter accountUpdateInstallationNameDTO was null or undefined when calling updateInstallationName.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/v1.0/installation/accounts/${encodeURIComponent(String(id))}/name`,
      accountUpdateInstallationNameDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
